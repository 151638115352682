import {Events, FedopsInteractions} from './constants';
import {withMembersArea} from '@wix/members-area-integration-kit';
import webLogger from '@wix/web-bi-logger';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {getAppManifest} from '../common/appManifest/appManifest';
import {DependantApps} from './services/DependantApps';
import {StyleParams} from './services/StyleParams';
import {translateFunctionFactory} from '../common/translations/translations';
import {
  errorReporter,
  setSentryInstance,
  withErrorReportingWrapping,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {
  addMembersArea,
  addPage,
  addWidget,
  openEcomBillingPage,
  updateEcomPlatformInstallations,
  updateEcomPlatformInstallationsData,
  updateEcomPagesForPagesPanel,
  getEcomPlatformAppManifest,
  setStateForPages,
  setStateForStoresPages,
  setThankYouPageStyleParams,
  installWishlistApp,
  addRouter,
  getRouter,
  connectPageToRouter,
  getRouterRefByPrefix,
} from './editor-script-partials/publicApi';
import {handleOnEvent} from './editor-script-partials/onEvent';
import {doTransaction} from './transaction';
import {handleAction} from './editor-script-partials/handleAction';
import {createAppApi} from './editor-script-partials/appApi';
import {ExperimentsApi} from '../common/experiments/ExperimentsApi';
import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {EditorSDK, ContextParams, PageRef, RouterRef} from '@wix/platform-editor-sdk';
import {EcomComponentsData, EcomPlatformInstallationState} from './services/EcomPlatformInstallationState';
import {EcomComponent} from '@wix/ecom-platform-sdk';
import {createSentryInstance} from '../common/monitoring';
import {EcomStyleParams} from '@wix/ecom-platform-sdk/dist/es/src/types';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';
import {createOOIWebBILogger} from '@wix/wixstores-client-core';
import {IInstallReviewsPluginProps, installReviewsPlugin} from './editor-script-partials/reviews';
import {WIX_ECOM} from '@wix/app-definition-ids';

let appToken;
let options: ContextParams;
let t: (keyToTranslate: string) => string;
let locale: string = 'en';
let webBiLogger: WebBiLogger;
let sdk: EditorSDK;
let experimentsApi: ExperimentsApi;
let dependantApps: DependantApps;
let appApi: AppApiModel;
let ecomPlatformInstallationState;
let styleParams: StyleParams;
let storeId: string;
let fedopsLogger: Record<string, any>;

function registerEvents() {
  return sdk.document.application.registerToCustomEvents('', {eventTypes: [Events.dashboardClosed]});
}

async function editorReady(_editorSDK: EditorSDK, _appToken, _options) {
  options = _options;
  appToken = _appToken;
  sdk = _editorSDK;

  const instance: string = options.initialAppData.instance;
  experimentsApi = new ExperimentsApi(instance);
  await experimentsApi.fetch();
  ecomPlatformInstallationState = new EcomPlatformInstallationState(experimentsApi);

  fedopsLogger = options?.monitoring?.createFedopsLogger()('ecom-platform-editor-script');

  if (options.firstInstall) {
    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformInstallationEditorReady);
    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformInstallationAppInstalled);
  }

  setSentryInstance(createSentryInstance(options.monitoring));
  // wrapAsyncFunctionsWithPromise(true);
  await registerEvents();

  const encodedInstance = instance.substring(instance.indexOf('.') + 1);
  const parsedInstance = JSON.parse(atob(encodedInstance));
  storeId = parsedInstance.instanceId;
  const isMerchant = true;

  const user = {uuid: parsedInstance.uid};
  webBiLogger = createOOIWebBILogger({
    user,
    biLoggerFactory: webLogger.factory,
    biToken: parsedInstance.biToken,
    defaults: {
      storeId,
      isMerchant,
      appName: 'wixstores worker',
    },
  });

  dependantApps = new DependantApps(sdk, instance, options.initialAppData.metaSiteId, webBiLogger);
  styleParams = new StyleParams(sdk, options.initialAppData.applicationId);
  locale = (await sdk.editor.environment.getLocale()) || locale;
  t = await translateFunctionFactory(locale);
  appApi = createAppApi({sdk, dependantApps, styleParams, t, webBiLogger});
  await sdk.editor.setAppAPI('', withErrorReportingWrapping(appApi));
  await doTransaction(sdk, async () => {
    await dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded({biData: options.biData});
  });

  if (options.firstInstall) {
    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformInstallationEditorReady);
  }
}

export function getPublicApi(editorSDK: EditorSDK, contextParams: ContextParams) {
  return {
    addPage: (pageId: string, managingAppDefId = WIX_ECOM, shouldNavigate = false) =>
      addPage(editorSDK, pageId, managingAppDefId, shouldNavigate, fedopsLogger, errorReporter),
    addWidget: (widgetId: string, addToAllPages: boolean) =>
      addWidget(editorSDK, widgetId, addToAllPages, fedopsLogger, errorReporter),
    getRouterRefByPrefix: (prefix: string) => getRouterRefByPrefix(editorSDK, prefix),
    addRouter: (prefix: string) => addRouter(editorSDK, prefix, errorReporter),
    getRouter: (routerRef: RouterRef) => getRouter(editorSDK, routerRef),
    connectPageToRouter: (connectPageOptions: {pageRef: PageRef; routerRef: RouterRef; pageRoles: string[]}) =>
      connectPageToRouter(editorSDK, connectPageOptions, errorReporter),
    addMembersArea: (
      editorType: string,
      firstInstall: boolean,
      biData?: {origin?: string},
      siteCreationFlow: boolean = false
    ) => addMembersArea(editorType, firstInstall, biData, siteCreationFlow, fedopsLogger),
    setStateForStoresPages: () => setStateForStoresPages(editorSDK),
    setStateForPages: () => setStateForPages(editorSDK),
    openEcomBillingPage: () => openEcomBillingPage(editorSDK),
    updateEcomPlatformInstallations: (appDefId: string, installedComponents: EcomComponent[]) =>
      updateEcomPlatformInstallations(ecomPlatformInstallationState, appDefId, installedComponents),
    updateEcomPlatformInstallationsData: (appDefId: string, ecomComponentsData: EcomComponentsData) =>
      updateEcomPlatformInstallationsData(ecomPlatformInstallationState, appDefId, ecomComponentsData),
    updateEcomPagesForPagesPanel: () => updateEcomPagesForPagesPanel(editorSDK, ecomPlatformInstallationState),
    getEcomPlatformAppManifest: () => getEcomPlatformAppManifest(t, locale),
    getInstance: () => contextParams.initialAppData.instance,
    setThankYouPageStyleParams: (thankYouPageStyleParams: EcomStyleParams) =>
      setThankYouPageStyleParams({
        sdk: editorSDK,
        ecomPlatformInstallationState,
        styleParams: thankYouPageStyleParams,
        experimentsApi,
      }),
    installWishlistApp: () => installWishlistApp({sdk: editorSDK, dependantApps, t, webBiLogger}),
    installReviewsPlugin: ({targetSlot, widgetPluginPointer}: IInstallReviewsPluginProps) =>
      installReviewsPlugin({editorSDK, targetSlot, widgetPluginPointer}),
  };
}

export const editorScript = withMembersArea(
  withErrorReportingWrapping({
    editorReady,
    handleAction: (data, editorSdk) =>
      handleAction(data, {sdk: editorSdk, dependantApps, ecomPlatformInstallationState, webBiLogger, fedopsLogger}),
    getAppManifest: ({appManifestBuilder}: {appManifestBuilder: AppManifestBuilder}, editorSDK: EditorSDK) =>
      getAppManifest({
        t,
        locale,
        token: appToken,
        hasStoresPremium: experimentsApi.hasStoresPremium,
        exps: experimentsApi.experiments,
        appManifestBuilder,
        editorSDK,
        options,
        fedopsLogger,
        pagesPanelTitle: t('Pages_Panel_ecomPlatformPages'),
      }),
    onEvent: (data, editorSDK) => handleOnEvent(data, editorSDK, dependantApps, experimentsApi),
    getControllerPresets: () => Promise.resolve([]),
    exports: (editorSDK: EditorSDK, contextParams: ContextParams) => ({
      public: getPublicApi(editorSDK, contextParams),
    }),
  }),
  {installAutomatically: false}
);

export type EcomPublicApi = ReturnType<typeof getPublicApi>;
